import { useState, useEffect } from 'react';
import needle from '../data/media/needle.png';
import back from '../data/media/back.png';
import configs from '../data/configs';
import '../styles/Compass400.css'
import { GetBearing, GetDeclination } from '../components/LocationHelper';
import { useSearchParams } from "react-router-dom";

function Compass400() {
    const [rotation, setRotation] = useState(0);
    const [degree, setDegree] = useState(0);
    const [searchParams] = useSearchParams();
    const [isLoading, setIsLoading] = useState(true)
    const lat = searchParams.get("lat")
    const lng = searchParams.get("lng")
    const city = searchParams.get("city")
    
    useEffect(() => {
        const setBearingAndDeclination = async () => {
            const bearing = GetBearing(lat, lng)
            const declination = await GetDeclination(lat, lng)
            setRotation((360 - (bearing - Number(declination.value))))
            setDegree((Number(rotation / 0.9).toFixed(configs.decimal) / 10).toFixed(1))
            setIsLoading(false)
        }
        setBearingAndDeclination()
    })

    return (
        <div>
            {isLoading ? (
                null
            ) : (
                <div className="container">
                    <div className="compass">
                        <img
                            src={back}
                            alt="back"
                            className="backImage"
                        />
                        <img
                            src={needle}
                            alt="needle"
                            className="needleImage"
                            style={{ transform: `rotate(${rotation}deg)` }}
                        />
                    </div>
                    {city && city !== "undefined" ? (
                        <p className="city">{city}<br />{ degree }</p>
                    ) : (
                        null
                    )}
                </div>
            )}
        </div>
    )
}
export default Compass400