import { useEffect, useRef } from 'react';
import { MapContainer, TileLayer, Marker, Popup, Polyline, LayersControl } from 'react-leaflet'
import { useSearchParams } from "react-router-dom";
import configs from '../data/configs';
import '../styles/Map.css';

function Map() {
    const mapRef = useRef(null)
    const markerRef = useRef(null)
    const [searchParams] = useSearchParams();
    const lat = searchParams.get("lat")
    const lng = searchParams.get("lng")

    useEffect(() => {
        function openAndCenterMarker() {
            const map = mapRef.current
            if (!map) {
                return
            }
            map.attributionControl.setPrefix("")
            map.flyTo([Number(lat), Number(lng)], configs.zoomLevel)
            const marker = markerRef.current
            if (marker) {
                marker.openPopup()
            }
        }
       
        openAndCenterMarker()
    })

    const { BaseLayer } = LayersControl

    return (
        <div id="mapBox">
            <div id="mapContainer">
                <MapContainer
                    center={[lat, lng]}
                    zoom={configs.zoomLevel}
                    ref={mapRef}
                    whenReady={(map) => {
                        mapRef.current = map
                    }}
                    >
                    <LayersControl>
                        <BaseLayer name="Carte">
                            <TileLayer
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                maxZoom={20}
                            />
                        </BaseLayer>

                        <BaseLayer checked name="Satellite">
                            <TileLayer
                                url='https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}'
                                maxZoom={20}
                                subdomains={['mt1', 'mt2', 'mt3']}
                            />
                        </BaseLayer>

                    </LayersControl>

                    <Marker ref={markerRef} position={[lat, lng]}>
                        <Popup>
                            Location: {lat}, {lng}
                        </Popup>
                    </Marker>
                    <Marker position={[configs.kaaba.lat, configs.kaaba.lng]}>
                        <Popup >
                            Kaaba {configs.kaaba.lat}, {configs.kaaba.lng}
                        </Popup>
                    </Marker>
                    <Polyline positions={[[lat, lng], [configs.kaaba.lat, configs.kaaba.lng]]} />
                </MapContainer>
            </div>
        </div>
    )

}

export default Map