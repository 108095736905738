import { useState, useEffect } from 'react';
import { getLatLngFromAddress } from '../components/LocationHelper';
import { useSearchParams } from "react-router-dom";

export default function Info() {
    const [searchParams] = useSearchParams();
    const address = searchParams.get("address")
    const [informations, setInformations] = useState(null);
    const getAddressInformations = async () => { 
        const result = await getLatLngFromAddress(address)
    }

    useEffect(() => { 
        getAddressInformations(address)
    }, [])

    return (
        <>
            <h1>Qiblah</h1>
        </>
    );
}