import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Info from "./routes/info";
import Error from "./routes/error";
import Map from "./routes/map";
import Compass400 from "./routes/compass400";
import './styles/App.css';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Info />,
    errorElement: <Error />,
  },
  {
    path: "map",
    element: <Map />,
  },
  {
    path: "compass400",
    element: <Compass400 />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <RouterProvider router={router} />
);

